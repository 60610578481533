.cardss,
.envelope {
  background: #fff;
  box-shadow: -1px 8px 3px 0 rgba(0, 0, 0, 0.6);
  height: 21em;
  padding: 2em;
  position: relative;
  width: 15em;
}
.cont{
  display: flex;
  justify-content: center;
  margin-top: 100px;
  
}

.envelope {
  background: rgb(179, 251, 227);
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.8);
  margin: -2em -2em;
  position: absolute;
  transform: rotate(-8deg);
  z-index: -1;
}

/* h1 {
  font-size: 2.5em;
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;
  width: 90%;
} */

em {
  color: #cc2119;
  font-style: normal;
}

.heart {
  bottom: 4em;
  position: absolute;
  right: 4em;
}

.heart::before,
.heart::after {
  background-color: #cd231b;
  border-radius: 50px 50px 0 0;
  content: "";
  height: 25px;
  left: 15px;
  position: absolute;
  transform: rotate(-45deg);
  transform-origin: 0 100%;
  width: 15px;
}

.heart::after {
  left: 0;
  transform: rotate(45deg);
  transform-origin: 100% 100%;
}