* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.tengah{
  /* display: flex;
  align-items: center; */
  /* justify-content: center; */
  display: block;
  text-align: center;
}
.tengah h3{
margin-bottom: 10px;
}

#player{
  display: none;
}
.cards-Open .content {
  display: flex;
  justify-content: center;
  align-items: center;

  
}

.container {
  max-width: 100vh;
  margin-left: auto;
  margin-right: auto;
  /* padding: 30px 0; */
  
}
.cards-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-left: 15px;
  padding-right: 15px;
}

.cards-Open {
  max-width: 100%;
  height:70%;
  position: relative;
  box-shadow: 0 0 15px rgba(0, 0, 0, .2);
  /* margin: 25px 0; */
  width: 100%;
}

@media screen and (max-width: 700px) {
  .cards-Open {
    width: 45%;
  }
}
@media screen and (max-width: 500px) {
  .cards-Open {
    width: 90%;
  }
}
.cards-Open .elms-animation,
.cards-Open .content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.cards-Open .elms-animation {
  z-index: 5;
}
.cards-Open .elms-animation span {
  position: absolute;
  background-color: rgba(0, 0, 0, .6);
  height: 0;
  width: 0;
  transition: all .5s ease-out;
}
.cards-Open .elms-animation span.one {
  top: 0; left: 0;
}
.cards-Open .elms-animation span.two {
  top: 0; right: 0;
}
.cards-Open .elms-animation span.three {
  left: 0; bottom: 0;
}
.cards-Open .elms-animation span.four {
  right: 0; bottom: 0;
}

.cards-Open:hover .elms-animation span {
  width: 50%;
  height: 50%;
}

.cards-Open img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  display: block;
}

.cards-Open .content {
  z-index: 6;
  visibility: hidden;
}
.cards-Open:hover .content {
  animation: show .3s .4s linear forwards;
}
.cards-Open .content button {
  padding: .6rem 1.3rem;
  border: 3px solid #FFF;
  background: transparent;
  color: #FFF;
  font-size: 1rem;
  outline: none;
  cursor: pointer;
  transition: background .3s ease;
}
.cards-Open .content button:hover {
  background: #FFF;
  color: #333;
}

@keyframes show {
  to {
    visibility: visible;
  }
}